.footer__container {
  width: 400px;
  background-color: #fff;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.footer__wrapper {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.footer__wrapper > span:nth-child(1) {
  display: flex;
  gap: 10px;
}

.footer__wrapper > span > button:nth-child(1) {
  background-color: var(--bg-red-save);
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 20px;
  width: 10rem;
  padding: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.footer__wrapper > span > button:nth-child(2) {
  background-color: var(--bg-red-cansel);
  color: var(--c-main);
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 20px;
  width: 5rem;
  padding: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.footer__wrapper > span > svg {
    color: var(--c-main);
    background-color: var(--bg-red-cansel);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    margin-top: 7px;
}
