.partTwo__container {
  gap: 10px;
  margin-top: 2rem;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 2px dashed var(--c-main);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.partTwo__wrapper {
  font-family: Roboto;
  font-weight: 400;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.partTwo__wrapper > p {
  font-weight: 400;
  color: var(--c-main);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.partTwo__wrapper__one,
.partTwo__wrapper__two {
  display: flex;
  flex-direction: column;
  position: relative;
}

.partTwo__wrapper label {
  position: absolute;
  left: 1rem;
  top: -17px;
  font-family: "Roboto", sans-serif;
  color: var(--c-main);
  font-weight: 700;
}

.partTwo__wrapper input,
.partTwo__wrapper textarea {
  outline: none;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  text-align: left;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.partTwo__wrapper__two {
  margin-top: 10px;
}

.partTwo__wrapper__three,
.partTwo__wrapper__four,
.partTwo__wrapper__five {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.partTwo__wrapper__three > span,
.partTwo__wrapper__four > span {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
  border-radius: 10px;
}

.partTwo__wrapper__three > span > label,
.partTwo__wrapper__four > span > label,
.partTwo__wrapper__five > span > label {
  font-size: 12px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.partTwo__wrapper__three > span > div,
.partTwo__wrapper__four > span > div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.partTwo__wrapper__three > span > div > svg,
.partTwo__wrapper__four > span > div > svg {
  font-size: 16px;
  background-color: lightgray;
  color: var(--c-main);
  width: 1.75rem;
  height: 1.75rem;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.partTwo__wrapper__five {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.5rem;
  width: 100%;
}

.partTwo__wrapper__five > span {
  position: relative;
  width: 50%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.partTwo__wrapper__five > span > svg {
  font-size: 20px;
  color: var(--c-main);
  width: 1.75rem;
  height: 1.75rem;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.partTwo__wrapper__six {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.partTwo__wrapper__six > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: 6.5rem;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.partTwo__wrapper__six > span > svg {
  color: var(--bg-red-save);
  font-size: 24px;
}

.partTwo__wrapper__six > span > p {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: var(--c-main);
}

.partTwo__wrapper__four > span div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.partTwo__wrapper__four > span div p {
    width: 2rem;
    height: 2rem;
    background-color: var(--c-main);
    color: var(--c-main);
    border-radius: 50%;
}