.preview__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
}

.preview__container > p {
    color: var(--c-main);
    text-align: center;
    opacity: .8;
}

.preview__container > div {
    background-color:#fff;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.preview__container > div > h5 {
    font-size: 18px;
}

.preview__container > div > small {
    opacity: .8;
}