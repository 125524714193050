@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #eee;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  direction: rtl;
}

:root {
  --c-main : #323232;
  --bg-red-save : #FF4340;
  --bg-red-cansel : rgba(255, 67, 64, 0.3);
}